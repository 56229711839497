import React, { useState } from "react"

import { graphql, navigate } from "gatsby"
import moment from "moment"

import CustomButton from "../components/button"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Banner from "../components/banner"
import BlogList from "../components/blogList"
import ConnectIcon from "../assets/connect-icon.svg"
import "./blogPost.css"

const content =
  "TAG Finance and Loans Pty Ltd ACN 609 906 863 Credit Representative Number 483873 have access to a panel of lenders via National Mortgage Brokers Pty Ltd ACN 093 874 376 / Australian Credit Licence 391209, which is a fully-owned subsidiary of Liberty Financial Pty Ltd ACN 077 248 983 / Australian Credit Licence 286596. Our brokers have access to products including those from Liberty Financial."

export const query = graphql`
  query ($id: String!, $category: String!) {
    wpPost(id: { eq: $id }) {
      title
      content
      date
      categories {
        nodes {
          name
        }
      }
      author {
        node {
          name
        }
      }
    }
    allWpPost(
      filter: {
        categories: { nodes: { elemMatch: { name: { eq: $category } } } }
        id: { ne: $id }
      }
      sort: { fields: [date], order: DESC }
    ) {
      nodes {
        title
        slug
        id
        excerpt
        categories {
          nodes {
            name
          }
        }
        date
      }
    }
  }
`

const BlogPost = ({
  data: {
    wpPost: {
      title,
      content: blogPost,
      date,
      categories: {
        nodes: {
          0: { name: category },
        },
      },
      author: {
        node: { name: author },
      },
    },
    allWpPost: { nodes: allPosts },
  },
  pageContext: { next, previous, nextTitle, previousTitle },
}) => {
  const [amount, setAmount] = useState(3)

  return (
    <Layout subfooter={content}>
      <SEO title="Insights" />

      <div className="blog-post-container">
        <Banner />
        <div
          style={{
            position: "relative",
            top: "9vw",
            "z-index": 1,
            paddingBottom: "10%",
          }}
        >
          <div className="blog-post-body">
            <h4 className="blog-post--header">
              {category} | {moment(date).format("Do MMMM YYYY")}
            </h4>
            <h3 className="blog-post--title">{title}</h3>
            <div>
              <div
                className="blog-post-content"
                dangerouslySetInnerHTML={{
                  __html: blogPost.replace(
                    `<img loading="lazy" width="1024" height="512"`,
                    `<img loading="lazy"`
                  ),
                }}
              ></div>
            </div>
          </div>
          <h3 className="blog-post-card-header">Related Insights</h3>
          <BlogList blogPreviews={allPosts} amount={amount} />
          {allPosts.length > amount && (
            <div className="blog-button">
              <CustomButton
                variant="secondary"
                className="blog-button-content"
                onClick={() => setAmount(amount + 3)}
              >
                View more
              </CustomButton>
            </div>
          )}
          <div className="blog-post--banner">
            <div className="blog-post-banner--circle-image">
              <ConnectIcon className="blog-post-banner--icon" />
            </div>
            <div className="blog-post-banner--header">
              Connect with Compare n Save
            </div>
            <div className="blog-post-banner--button">
              <CustomButton
                variant="secondary"
                className="blog-post-banner--content"
                onClick={() => navigate("/contact")}
              >
                Request a call back
              </CustomButton>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default BlogPost
