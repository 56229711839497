import React from "react"
import { Button } from "react-bootstrap"
import "./button.css"
const CustomButton = ({ children, variant, className, onClick }) => {
  return (
    <Button variant={variant} className={`${className ? className : ''} custom-button`} onClick={onClick}>
      {children}
    </Button>
  )
}

export default CustomButton
