import React from "react"
import LogoBanner from "../assets/logo-banner.svg"
import "./banner.css"

const Banner = () => {
  return (
    <div className="banner-container">
      <div className="banner-logo-container">
        <LogoBanner className="banner-logo" />
      </div>
    </div>
  )
}

export default Banner
