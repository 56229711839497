import React from "react"
import { navigate } from "gatsby"
import moment from "moment"

import { Card } from "react-bootstrap"
import "./blogList.css"

const BlogList = ({ blogPreviews, amount }) => {
  return (
    <div className={amount === 4 ? "blog-previews-4" : "blog-previews-12"}>
      {blogPreviews.slice(0, amount).map(
        ({
          title,
          excerpt,
          id,
          slug,
          date,
          categories: {
            nodes: {
              0: { name: category },
            },
          },
        }) => {
          const newExcerpt = excerpt.split("Continue reading")[0]
          const truncateExcerptWithoutCuttingWords = (excerpt, maxLengths) => {
            const windowWidth =
              typeof window !== "undefined" ? window.innerWidth : 0
            let maxLength

            if (windowWidth <= 576) {
              maxLength = maxLengths.xs
            } else if (windowWidth <= 768) {
              maxLength = maxLengths.sm
            } else if (windowWidth <= 1024) {
              maxLength = maxLengths.md
            } else if (windowWidth <= 1440) {
              maxLength = maxLengths.lg
            } else if (windowWidth <= 1800) {
              maxLength = maxLengths.xl
            } else {
              maxLength = maxLengths.xxl
            }

            if (excerpt.length <= maxLength) {
              return excerpt
            }

            const lastSpaceIndex = excerpt.lastIndexOf(" ", maxLength)
            return excerpt.substring(0, lastSpaceIndex) + " ..."
          }
          return (
            <div key={id}>
              <Card
                className="blog-card"
                onClick={() => navigate(`/entry/${slug}`)}
              >
                <div className="blog-header">
                  {category} | {moment(date).format("Do MMMM YYYY")}
                </div>

                <div className="blog-title">{title}</div>

                <Card.Body
                  className="blog-excerpt"
                  dangerouslySetInnerHTML={{
                    __html: truncateExcerptWithoutCuttingWords(newExcerpt, {
                      xs: 100,
                      sm: 170,
                      md: 250,
                      lg: 200,
                      xl: 200,
                      xxl: 250,
                    }),
                  }}
                ></Card.Body>
              </Card>
            </div>
          )
        }
      )}
    </div>
  )
}

export default BlogList
